<template>
  <div class="group-tree">
    <!-- 虚拟滚动树 -->
    <!-- 标题框 -->
    <div v-if="showTitle"
      class="group-title-wrap">
      <span class="title-left">选择车组</span>
      <div class="title-right">
        <el-link :underline="false"
          type="primary"
          style="margin-right:15px"
          @click="selectAll">全选</el-link>
        <el-link :underline="false"
          type="primary"
          @click="cancelAll">取消全选</el-link>
      </div>
    </div>
    <!-- 搜索框 -->
    <div v-if="isSearch" style="padding-bottom:8px">
      <el-input v-model="searchGroup"
        placeholder="搜索车组" @input="hadnleSearch"
        clearable>
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <!-- <el-autocomplete style="width: 100%"
        v-model="searchGroup"
        :fetch-suggestions="querySearchAsync"
        placeholder="请输入内容"
        clearable
        @select="handleSelect"></el-autocomplete> -->
    </div>

    <div class="GroupTreeContent"
      v-loading="treeLoading">
      
      <el-tree
        class="filter-tree" show-checkbox
        :data="groupList" node-key="id"
        :props="defaultProps"
        default-expand-all
        :filter-node-method="filterNode" @check="handleChangeCheck"
        ref="tree">
      </el-tree>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VirtualTree from '@/components/VirtualTree/NewTree';
import GroupAvator from './GroupAvator';
import { projectV2List } from '@/api/riskData.js';
import { arrayTreeSort } from '@/utils/treeHelper.js';
import { isObject } from 'lodash';
import { sleepIf } from '@/utils/sleep';
import { hasPerms, dataPermissions } from "@/utils/auth";

export default {
  name: 'ProjectTree',
  components: {
    VirtualTree,
    GroupAvator,
  },
  props: {

    // 是否添加标题框
    showTitle: {
      type: Boolean,
      default: false,
    },
    // 是否默认展开父节点
    defaultExpandAll: {
      type: Boolean,
      default: false,
    },
    // 是否在节点前添加复选框
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    // 是否取消父子节点关联
    checkStrictly: {
      type: Boolean,
      default: false,
    },
    //是否显示文字溢出显示省略号
    ellipsis: {
      type: Boolean,
      default: false
    },
    // 车组数据
    propGroupList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 无数据时显示
    propEmptyText: {
      type: String,
      default: '--'
    },
    //打勾的节点
    propCheckedList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 是否使用默认数组
    useDefault: {
      type: Boolean,
      default: false
    },
    selecteds: {//已选择的数据 用于修改功能 仅多选可用
      type: Array,
      default() {
        return []; // { label:车牌/组织, id:车辆ID/车组ID }
      }
    },
    // 是否显示搜索车组
    isSearch: {
      type: Boolean,
      default: false
    },
    // 定制选项 默认全选车组
    isCustomization: {
      type: Boolean,
      default: false
    },
    // 定制选项 默认全选车组
    isShowCount: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      // 用户绑定的数组
      checkedList: [],
      // 车组数据
      groupList: [],
      // 加载状态
      treeLoading: false,
      emptyText: '--',
      searchGroup: '',
      filterGroupList: [],
      checkGroupKeyList: [],
      defaultProps: {
          children: 'children',
          label: 'name'
      }
    };
  },

  computed: {
    ...mapState(['userInfo']),
    // 组织树
    // groupTreeData() {
    //   this.$emit('loadElement', this.groupList.length || 0);
    //   return arrayTreeSort(this.groupList, -1, { id: 'groupId', parentId: 'parentId' });
    // }
  },

  mounted() {
      this.loadGroupTree();
  },
  watch: {
    selecteds: {
      handler(v) {
        if (v && v.length) {
          const list = v.map(p => p.id);
          const groupList = JSON.parse(JSON.stringify(this.groupList));
            this.groupList = groupList.map(item => {
              return {
                ...item,
                checked: list.includes(item.id)
              };
            });
        }
      },
      deep: true,
    },
    // propCheckedList(val) {
    //   if (val.length !== 0) this.setCheckedArray(val, true);
    // },
    propGroupList(val) {
      console.log(val, '------------------------------------------');
      this.groupList = JSON.parse(JSON.stringify(val));
    },
    propEmptyText(val) {
      this.emptyText = val;
    }
  },
  methods: {

    // 全选车组
    async selectAll() {

      const { tree } = this.$refs;
      if (!tree) return;
      console.log(tree.setCheckedNodes, this.groupList);
      await tree.setCheckedNodes(this.groupList);
      const allCheckeds = tree.getCheckedNodes().map(obj => obj.data);
      this.onCheckChange(null, true, null, allCheckeds);
    },
    // 取消全选车组
    async cancelAll() {
      await this.$refs.tree.setCheckedKeys([]);
      this.onCheckChange(null, false, null, []);
      this.onCheckChangeGroup([], false);
    },
    /**
     * 获取子节点
     */
    getSubNodes(node, allSub = false) {
      const { virtualTreeRef } = this.$refs;
      if (!virtualTreeRef) return;
      return virtualTreeRef.getSubNodes(node, allSub);
    },

    /**
     * 获取所有勾选的节点
     */
    getCheckedNodes() {
      const { tree } = this.$refs;
      if (!tree) return;
      return tree.getCheckedNodes();
    },
    // 设置节点勾选状态
    setChecked(node, checked, checkStrictly) {
      const { tree } = this.$refs;
      if (tree) {
        tree.setChecked(node, checked, checkStrictly);
      }
    },
    setCheckedKey(key, checked) {
      const { tree } = this.$refs;
      if (tree) {
        tree.setCheckedKeys(Array.isArray(key) ? key : [key], checked);
      }
    },
    /**
    * 加载组织树
    */
    async loadGroupTree(type) {
      const userId = this.userInfo.userId;
      this.groupList = [];

      this.treeLoading = true;
      this.setEmptyText();
      try {
        // userId
        const result = await projectV2List();
        this.setEmptyText(result?.msg);
        this.$emit('loadElement', result.data.total || 0);
        if (result.code !== 200) return;
        if (!result.data.treeList) return;
        if (this.selecteds.length !== 0) { // 处理已选择数据
          const list = this.selecteds.map(p => p.id);
          this.groupList = result.data.treeList.map(item => {
            return {
              ...item,
              checked: list.includes(item.id)
            };
          });
          return;
        }
        this.filterGroupList = JSON.parse(JSON.stringify(result.data.treeList));
        this.groupList = result.data.treeList;
      } catch (err) {
        console.error(err);
      } finally {
        sleepIf(5000, () => !this.treeLoading)
          .then(() => this.treeLoading = false);
      }
    },
    // 节点点击事件
    onNodeClick(node) {
      // this.$emit('nodeClick', node);
    },
    // 节点勾选事件
    onCheckChange(data, checked, node, allCheckeds) {
      let checkList = [];
      if (!checked && data && data.parentId != -1) {
        checkList = this.groupTreeData.filter(val => (val.parentId == data.parentId && val.id != data.id));
      }
      this.$emit('checkChangeGroup', this.$refs.tree.getCheckedNodes(), checked);
      this.$emit('checkChange', data, checked, allCheckeds, node, checkList);
    },
    handleChangeCheck(e) {
      this.$emit('checkChangeGroup');
    },
    onCheckChangeGroup(data, checked) {
      // let groupIdList = data.map(item => item.id);
      // const groupList = [];
      // this.groupTreeData.map(item => {
      //   if (groupIdList.includes(item.parentId) || groupIdList.includes(item.id)) {
      //     groupIdList.push(item.id);
      //     groupList.push(item);
      //   }
      // });
      // this.checkGroupKeyList = groupList.map(item => item.id);
      this.$emit('checkChangeGroup', this.$refs.tree.getCheckedNodes(), checked);
    },
    // 设置无数据的显示文本
    setEmptyText(text = '--') {
      this.emptyText = text;
    },

    //重新加载
    reload() {
      if (this.treeLoading) return;

      return this.loadGroupTree();
    },

    // tree加载完成, 非准确
    async onTreeLoaded() {
      await this.$nextTick();
      this.treeLoading = false;
      const { currentKey } = this;
      if (currentKey) {
        this.setCurrentKey(currentKey);
      }
      if (this.isCustomization) {
        this.$nextTick(()=>{
          this.selectAll();
        })
      }
    },

    // 滚动到可视区
    scrollIntoView(key) {
      const { virtualTreeRef } = this.$refs;
      if (isObject(key)) {
        key = key.key;
      }

      virtualTreeRef && virtualTreeRef.setExpandedKey(key, true, true);
      virtualTreeRef && virtualTreeRef.setCurrentKey(key);
    },

    //树节点双击
    dblclick(node) {
      this.$emit('dbClick', node);
    },
    //设置数组勾选情况
    async setCheckedArray(arr, checked = false) {
      if (arr.length === 0) return;
      let keyList = arr.map(item => item.id);
      this.$refs.virtualTreeRef.setCheckedKeys(keyList, checked);
      this.$emit('checkChange', [], '', arr);
    },
    querySearchAsync(queryString, cb) {
      let restaurants = this.groupList.map(val => {
        val.value = val.groupName;
        return val;
      });
      let results = queryString ? restaurants.filter(val => val.groupName.indexOf(queryString) > -1) : [];
      cb(results);
    },
    hadnleSearch(e) {
      // this.groupList = JSON.parse(JSON.stringify(this.filterGroupList)).filter(val=> (val.groupName.indexOf(e) > -1) || e === '');
      this.$refs.tree.filter(e);
      // const { virtualTreeRef } = this.$refs;
      // if (virtualTreeRef) {
      //   virtualTreeRef.setCurrentKey(this.checkGroupList[0].groupId, true);
      // }
      // setCheckedNodes
    },
    handleSelect(e) {
      this.$refs.virtualTreeRef.onSelectCheckChange(e);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    }
  }
}
</script>

<style lang="scss" scoped>
.group-tree {
  position: relative;
  height: 100%;
  background-color: #fff;
  // padding-bottom: 10px;

  display: flex;
  flex-direction: column;
}
.GroupTreeContent {
  flex: 1;
  overflow: hidden;
}

.group-node {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nodeLabel {
    width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    font-size: 14px;
    color: #333;
  }
}
.group-title-wrap {
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
  border-bottom: 1px solid #ddd;
  background: #e1e1e1;
  .title-left {
    font-size: 15px;
    font-weight: bold;
  }
  .title-right {
  }
}
</style>
