<template>
  <el-dialog :visible.sync="dialogVisible"
    @close="clearClose" :close-on-click-modal="false"
    custom-class="add-risk-dialog" :title="!editData.id ? '添加' : '编辑'"
    width="616px">
    <div slot="footer">
      <el-button @click="clearClose">关 闭</el-button>
      <el-button @click="confirm" type="primary">确 认</el-button>
    </div>
    <el-form :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="150px"
      size="mini">
      <el-form-item label="项目名称："
        prop="lsProjectName">
        <el-input v-model="form.lsProjectName"
          clearable></el-input>
      </el-form-item>
      <el-form-item label="上级项目：" v-if="tablePagination.level > 1"
        prop="parentId">
        <el-select v-model="form.parentId" filterable placeholder="请选择">
          <el-option
            v-for="item in productTypeList"
            :key="item.id"
            :label="item.lsProjectName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import VDistpicker from "v-distpicker";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import { inputNum } from "@/views/routerProjectManagement/components/ProjectList/components/utils.js";
import selectorStaff from "@/views/SystemManagement/components/staff/selectorStaffNew.vue";
import { insSaveProject, insUpdateProject } from "@/api/basicData";
import { dictionaryBatch } from "@/api/policy";
import { addProject } from "@/api/project";
import { saveOrUpdate, projectLSList } from "@/api/riskData.js";
export default {
  name: "addctbxProjectDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: ()=> {}
    },
    tablePagination: {
      type: Object,
      default: ()=> {}
    }
  },
  components: { VDistpicker, EmployeeSelect, selectorStaff },
  data() {
    return {
      dialogVisible: false,
      topLevelId: 0,
      riskType: [],
      productTypeList: [],
      form: {
        lsProjectName: '',
        parentId: '',
      },
      rules: {
        parentId: [
          { required: true, message: "请选择上级项目", trigger: "change" },
        ],
        lsProjectName: [
          { required: true, message: "请输入项目名称", trigger: "change" },
        ],
      },
    };
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          if (this.tablePagination || this.tablePagination.level > 1) {
            this.init();
          }
          if (this.editData.id) {
            let data = JSON.parse(JSON.stringify(this.editData));
            this.$nextTick(()=>{
              Object.assign(this.form, data);
            })
          }
        } else {
          this.dialogVisible = false;
        }
      }
    }
  },
  mounted() { },
  methods: {
    reset() {
      this.clearClose();
    },
    init() {
      projectLSList({ level: "1", index: 1, size: 99999, type: this.tablePagination.type }).then((res) => {
        this.productTypeList = res?.data?.records || [];
      });
    },
    confirm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const api = saveOrUpdate;
          let emitData = {...this.tablePagination, ...JSON.parse(JSON.stringify(this.form))};
          emitData.userId = this.$store.state.userInfo.MJUserId;
          emitData.createUserName = this.$store.state.userInfo.name;
          emitData.parentId ? '' : emitData.parentId = -1,
          api(emitData).then(res=>{
            this.dialogVisible = false;
            this.$emit('changeList');
            this.clearClose();
          }).catch(err=>{
            console.log(err);
          });
          // if (this.actionType == "edit") {
          //   emitData = {
          //     ...this.form,
          //   };
          // }
          // this.$emit("confirm", emitData);
          // this.visible = false;
        }
      });
    },
    clearClose() {
      this.dialogVisible = false;
      this.form = {
        lsProjectName: '',
        parentId: ''
      };
      this.$refs.form.resetFields();
    },
    onSelected(e) {
      this.form.dangerProvince = e.province.value;
      this.form.dangerCity = e.city.value;
      this.form.dangerCounty = e.area.value;
      this.form.riskLocations = e.province.value + e.city.value + e.area.value;
    },
    input_num(e, tiem, key, max, decimal = 2) {
      inputNum(e, tiem, key, max, decimal);
    },
    handleChangProductType(e) {
      let data = this.productTypeList.find(p => p.dictValue == e)
      this.form.productTypeValue = data.id;
    }
  },
};
</script>

<style lang="scss">
.add-risk-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 16px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    padding: 0 24px;
    background: #fff;
    border-bottom: 1px solid #F5F5F5;
    margin-bottom: 14px;
    .el-dialog__title {
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 0;
  }
  .el-form-item {
    width: calc(100% - 80px) !important;
  }
}
</style>
