<template>
  <el-dialog
    :visible.sync="dialogVisible"
    @close="reset"
    :close-on-click-modal="false"
    custom-class="add-risk3-dialog"
    title="内容设置"
    width="969px"
  >
    <div slot="footer">
      <el-button @click="reset">关 闭</el-button>
      <el-button @click="confirm" type="primary">确 认</el-button>
    </div>
    <div>
      <div class="type-select">
        <div
          class="item"
          :class="{ active: curType === 'bk' }"
          @click="handleSelect('bk')"
        >
          布控地图
        </div>
        <div
          class="item"
          :class="{ active: curType === 'sj' }"
          @click="handleSelect('sj')"
        >
          事件地图
        </div>
      </div>
      <configuredTable v-model="tableconfigured[curType]" />
    </div>
  </el-dialog>
</template>

<script>
import { inputNum } from "@/views/routerProjectManagement/components/ProjectList/components/utils.js";
import configuredTable from "@/views/OperationBigScreen/maintenanceConfiguration/components/configuredTable.vue";
import { insSaveProject, insUpdateProject } from "@/api/basicData";
import { projectLSContentList, projectLSContent } from "@/api/riskData";
import { addProject } from "@/api/project";
export default {
  name: "addctbxProjectDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },
  components: { configuredTable },
  data() {
    return {
      dialogVisible: false,
      topLevelId: 0,
      riskType: [],
      productTypeList: [],
      curType: "bk",
      tableconfigured: {
        bk: [
          {
            label: "项目信息",
            tableData: [
              {
                dataType: 1,
                sort: 1,
                isDisplayed: true,
              },
              {
                dataType: 2,
                sort: 2,
                isDisplayed: true,
              },
              {
                dataType: 3,
                sort: 3,
                isDisplayed: true,
              },
            ],
            tableDataList: [
              {
                prop: "dataType",
                label: "数据项",
                columnType: "text",
                width: "120",
              },
              {
                prop: "sort",
                label: "排序",
                columnType: "input",
              },
              {
                prop: "isDisplayed",
                label: "是否显示",
                columnType: "switch",
              },
            ],
          },
          {
            label: "报警信息",
            tableData: [
              {
                dataType: 4,
                sort: 1,
                isDisplayed: true,
                duration: 3,
              },
              {
                dataType: 5,
                sort: 2,
                isDisplayed: true,
                duration: 3,
              },
              {
                dataType: 6,
                sort: 3,
                isDisplayed: true,
                duration: 3,
              },
              {
                dataType: 7,
                sort: 4,
                isDisplayed: true,
                duration: 3,
              },
              {
                dataType: 8,
                sort: 5,
                isDisplayed: true,
                duration: 3,
              },
              {
                dataType: 9,
                sort: 6,
                isDisplayed: true,
                duration: 3,
              },
            ],
            tableDataList: [
              {
                prop: "dataType",
                label: "数据项",
                columnType: "text",
                width: "120",
              },
              {
                prop: "sort",
                label: "排序",
                columnType: "input",
              },
              {
                prop: "isDisplayed",
                label: "是否显示",
                columnType: "switch",
              },
              {
                prop: "duration",
                label: "轮播时间(s)",
                columnType: "input",
              },
            ],
          },
        ],
        sj: [
          {
            label: "报警信息",
            tableData: [
              {
                dataType: 10,
                isDisplayed: true,
              },
              {
                dataType: 11,
                isDisplayed: true,
              },
              {
                dataType: 12,
                isDisplayed: true,
              },
              {
                dataType: 13,
                isDisplayed: true,
              },
              {
                dataType: 14,
                isDisplayed: true,
              },
            ],
            tableDataList: [
              {
                prop: "dataType",
                label: "数据项",
                columnType: "text",
                width: "120",
              },
              {
                prop: "isDisplayed",
                label: "是否显示",
                columnType: "switch",
              },
            ],
          },
        ],
      },
      xmxxDefault: [
        {
          dataType: 1,
          sort: 1,
          isDisplayed: true,
        },
        {
          dataType: 2,
          sort: 2,
          isDisplayed: true,
        },
        {
          dataType: 3,
          sort: 3,
          isDisplayed: true,
        },
      ],
      bjxxDefault: [
        {
          dataType: 4,
          sort: 1,
          isDisplayed: true,
          duration: 3,
        },
        {
          dataType: 5,
          sort: 2,
          isDisplayed: true,
          duration: 3,
        },
        {
          dataType: 6,
          sort: 3,
          isDisplayed: true,
          duration: 3,
        },
        {
          dataType: 7,
          sort: 4,
          isDisplayed: true,
          duration: 3,
        },
        {
          dataType: 8,
          sort: 5,
          isDisplayed: true,
          duration: 3,
        },
        {
          dataType: 9,
          sort: 6,
          isDisplayed: true,
          duration: 3,
        },
      ],
      sjbjxxDefault: [
        {
          dataType: 10,
          isDisplayed: true,
        },
        {
          dataType: 12,
          isDisplayed: true,
        },
        {
          dataType: 12,
          isDisplayed: true,
        },
        {
          dataType: 13,
          isDisplayed: true,
        },
        {
          dataType: 14,
          isDisplayed: true,
        },
      ],
      dataTypeNameList: [
        "",
        "数据概览",
        "报警统计",
        "安全指数",
        "实时报警",
        "超速报警",
        "AEB制动报警",
        "前向碰撞预警",
        "车距检测预警",
        "车道偏离预警",
        "超速报警",
        "AEB制动报警",
        "前向碰撞预警",
        "车距检测预警",
        "车道偏离预警",
      ],
    };
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          if (this.editData.id) {
            this.init();
          }
        } else {
          this.dialogVisible = false;
          this.reset();
        }
      },
    },
  },
  mounted() {},
  methods: {
    reset() {
      this.tableconfigured = {
        bk: [
          {
            label: "项目信息",
            tableData: [
              {
                dataType: 1,
                sort: 1,
                isDisplayed: true,
              },
              {
                dataType: 2,
                sort: 2,
                isDisplayed: true,
              },
              {
                dataType: 3,
                sort: 3,
                isDisplayed: true,
              },
            ],
            tableDataList: [
              {
                prop: "dataType",
                label: "数据项",
                columnType: "text",
                width: "120",
              },
              {
                prop: "sort",
                label: "排序",
                columnType: "input",
              },
              {
                prop: "isDisplayed",
                label: "是否显示",
                columnType: "switch",
              },
            ],
          },
          {
            label: "报警信息",
            tableData: [
              {
                dataType: 4,
                sort: 1,
                isDisplayed: true,
                duration: 3,
              },
              {
                dataType: 5,
                sort: 2,
                isDisplayed: true,
                duration: 3,
              },
              {
                dataType: 6,
                sort: 3,
                isDisplayed: true,
                duration: 3,
              },
              {
                dataType: 7,
                sort: 4,
                isDisplayed: true,
                duration: 3,
              },
              {
                dataType: 8,
                sort: 5,
                isDisplayed: true,
                duration: 3,
              },
              {
                dataType: 9,
                sort: 6,
                isDisplayed: true,
                duration: 3,
              },
            ],
            tableDataList: [
              {
                prop: "dataType",
                label: "数据项",
                columnType: "text",
                width: "120",
              },
              {
                prop: "sort",
                label: "排序",
                columnType: "input",
              },
              {
                prop: "isDisplayed",
                label: "是否显示",
                columnType: "switch",
              },
              {
                prop: "duration",
                label: "轮播时间(s)",
                columnType: "input",
              },
            ],
          },
        ],
        sj: [
          {
            label: "报警信息",
            tableData: [
              {
                dataType: 10,
                isDisplayed: true,
              },
              {
                dataType: 11,
                isDisplayed: true,
              },
              {
                dataType: 12,
                isDisplayed: true,
              },
              {
                dataType: 13,
                isDisplayed: true,
              },
              {
                dataType: 14,
                isDisplayed: true,
              },
            ],
            tableDataList: [
              {
                prop: "dataType",
                label: "数据项",
                columnType: "text",
                width: "120",
              },
              {
                prop: "isDisplayed",
                label: "是否显示",
                columnType: "switch",
              },
            ],
          },
        ]
      };
      this.clearClose();
    },
    handleSelect(e) {
      this.curType = e;
      if (this.editData.id) {
        // this.init();
      }
    },
    async confirm() {
      let dataList = [
        ...this.tableconfigured.bk[0].tableData,
        ...this.tableconfigured.bk[1].tableData,
      ];
      if ((this.tableconfigured.bk[0].tableData[0].id && this.tableconfigured.sj[0].tableData[0].id) || (!this.tableconfigured.bk[0].tableData[0].id && !this.tableconfigured.sj[0].tableData[0].id) || this.curType == "sj") {
        dataList.push(...this.tableconfigured.sj[0].tableData);
      }
      let data = await projectLSContent(
        dataList.map((val) => {
          val.projectLsId = this.editData.id;
          return val;
        })
      );
      this.dialogVisible = false;
    },
    clearClose() {
      this.dialogVisible = false;
      this.form = {
        projectName: "",
        productType: "",
        productTypeValue: "",
      };
    },
    async init() {
      let { data, code } = await projectLSContentList({
        lsProjectId: this.editData.id,
        type: '',
      });
      if (code == 200 && data.length) {
          let bk1 = data.filter((vv) => vv.dataType < 4);
          let bk2 = data.filter((vv) => vv.dataType > 3 && vv.dataType < 10);
          let bk3 = data.filter((vv) => vv.dataType > 9);
          this.tableconfigured.bk[0].tableData = bk1;
          this.tableconfigured.bk[1].tableData = bk2;
          this.tableconfigured.sj[0].tableData = bk3;
      } else {
        // this.tableconfigured[this.curType].tableData = [];
      }
    },
    onSelected(e) {
      this.form.dangerProvince = e.province.value;
      this.form.dangerCity = e.city.value;
      this.form.dangerCounty = e.area.value;
      this.form.riskLocations = e.province.value + e.city.value + e.area.value;
    },
    input_num(e, tiem, key, max, decimal = 2) {
      inputNum(e, tiem, key, max, decimal);
    },
    handleChangProductType(e) {
      let data = this.productTypeList.find((p) => p.dictValue == e);
      this.form.productTypeValue = data.dictKey;
    },
  },
};
</script>

<style lang="scss">
.add-risk3-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 16px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    padding: 0 24px;
    background: #fff;
    border-bottom: 1px solid #F5F5F5;
    .el-dialog__title {
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 0;
  }

  .type-select {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: -10px;
    border-radius: 10px 10px 0 0;
    background-color: #fff;
    .item {
      position: relative;
      padding: 13px 20px;
      color: #606266;
      margin-right: 20px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: #409eff;
      }
    }
    .active {
      color: #4278c9;
      font-weight: bold;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        border-radius: 4px;
        background-color: #4278c9;
      }
    }
  }
}
</style>
