<template>
  <ResizeObserver @resize="handleWrapperResize">
    <div scroll ref="viewportRef" class="VirtualList" @scroll="handleScroll">
      <Filler
        v-if="visibleData.length"
        :height="totalHeight"
        :offset="startOffset"
      >
        <div
          v-for="({key, item}) in visibleData"
          :key="key"
          :style="itemOuterStyle"
        >
          <slot :item="item" :itemKey="key"></slot>
        </div>
      </Filler>
      <template v-else>
        <slot name="empty"></slot>
      </template>
    </div>
  </ResizeObserver>
</template>

<script>
import { isString, throttle } from 'lodash';
import ResizeObserver from '@/components/ResizeObserver';
import Filler from './Filler.vue';


// 默认的元素高度
const DEFAULT_ITEM_HEIGHT = 26;
// 显示数据量倍数
const visibleRate = 1;

export default {
  name: 'VirtualList',
  components: {
    ResizeObserver,
    Filler,
  },
  props: {
    /**
     * 预估的元素高度
     */
    itemHeight: {
      type: Number,
      default: DEFAULT_ITEM_HEIGHT,
    },
    /**
     * 数据源
     */
    data: {
      type: Array,
      default: () => [],
    },
    /**
     * 元素的 key，或者获取 key 的函数
     */
    itemKey: {
      type: [String, Function],
      default: 'key',
    },
  },
  data() {
    return {
      // 可视区的高度
      viewportHeight: 200,
      startOffset: 0,
      // 分页
      pageObj: {
        // 开始下标
        startIndex: 0,
        // 结束下标(不包括)
        endIndex: 0,
      }
    };
  },
  computed: {
    // 将data数据转换为 格式: {key,index,item}
    internalData() {
      const { data } = this;
      return data.map((item, index) => ({
        key: this.getItemKey(item, index),
        index,
        item
      }));
    },
    /**
     * 显示数据
     */
    visibleData() {
      const { internalData, itemCount } = this;
      const { startIndex, endIndex } = this.pageObj;
      return internalData.slice(startIndex, endIndex);
    },
    /**
     * 总项目树
     */
    itemCount() {
      return this.data.length;
    },
    // 可视区渲染的数量
    visibleCount() {
      const { viewportHeight, itemHeight } = this;
      return Math.floor((viewportHeight / itemHeight) * visibleRate);
    },
    /**
     * 总高度
     */
    totalHeight() {
      const { internalData, itemHeight } = this;
      return internalData.reduce((sum, { key }) => sum + itemHeight, 0);
    },
    /**
     * 项目样式
     */
    itemOuterStyle() {
      const { itemHeight } = this;
      return {
        height: `${itemHeight}px`,
      };
    }
  },
  watch: {
    itemHeight() {
      this.updateVisibleData();
    },
    visibleCount() {
      this.updateVisibleData();
    },
  },
  created() {
    this.updateVisibleData = throttle(this.updateVisibleData, 50).bind(this);
  },
  beforeMount() {
    this.pageObj.endIndex = this.visibleCount;
  },
  mounted() {
    this.handleWrapperResize();
  },
  methods: {
    // 滚动到可视区
    scrollIntoView(item, index) {

      const {
        internalData,
        itemHeight,
        visibleCount, itemCount
      } = this;

      const { viewportRef } = this.$refs;
      if (!viewportRef) return;

      let {
        scrollTop,
        clientHeight,
        scrollHeight,
      } = viewportRef;

      const scrollLength = scrollHeight - clientHeight;

      const itemKey = this.getItemKey(item, index);

      const itemIndex = internalData.findIndex(p => p.key === itemKey);
      if (itemIndex < 0) return;
      // 目标元素位于视野之内时，直接退出

      const visibleHalf = Math.floor(visibleCount / 2);

      if (itemIndex <= visibleHalf) {
        scrollTop = 0;
      } else if (itemIndex >= (itemCount - visibleHalf)) {
        scrollTop = scrollLength;
      } else {
        scrollTop = (itemIndex - visibleHalf + 1) * itemHeight;
      }

      scrollTop = scrollTop < 0 ? 0 : scrollTop;
      scrollTop = scrollTop < scrollLength ? scrollTop : scrollLength;

      viewportRef.scrollTop = scrollTop;

    },


    // 重置可视区高度
    handleWrapperResize() {
      const { viewportRef } = this.$refs;
      if (!viewportRef) return;

      let {
        clientHeight,
      } = viewportRef;

      this.viewportHeight = clientHeight;
    },
    handleScroll(e) {
      const { viewportRef } = this.$refs;
      if (!viewportRef) return;
      this.updateVisibleData();
      this.$emit('scroll', e);
    },
    updateVisibleData() {
      const { viewportRef } = this.$refs;
      if (!viewportRef) return;

      const { itemCount, visibleCount, itemHeight } = this;

      let {
        scrollTop,
        clientHeight,
        scrollHeight,
      } = viewportRef;

      // 总可滚动的距离
      const scrollLength = scrollHeight - clientHeight;

      // 检验`scrollTop`, rang: [0, scrollLength]
      scrollTop = scrollTop < 0 ? 0 : scrollTop;
      scrollTop = scrollTop > scrollLength ? scrollLength : scrollTop;

      // scrollTop 可能为小数, 处理取整
      // scrollTop = Math.ceil(scrollTop);

      // 滚动比例
      let scrollPtg = scrollTop / scrollLength;
      scrollPtg = isNaN(scrollPtg) ? 0 : scrollPtg;

      // 保留2位小数
      scrollPtg = Math.ceil(scrollPtg * 100) / 100;

      // 滚动位置对应的元素
      const itemIndex = Math.floor(scrollPtg * itemCount);

      const beforeCount = Math.ceil(scrollPtg * visibleCount);
      const startIndex = Math.max(0, itemIndex - beforeCount);
      const endIndex = startIndex + visibleCount;
      this.pageObj.startIndex = startIndex;
      this.pageObj.endIndex = endIndex;

      let newStartOffset = scrollTop;

      let offsetDiff = visibleRate > 1 ? Math.ceil((visibleCount - visibleCount / visibleRate) * itemHeight) : 0;

      if (scrollPtg === 0) {
        newStartOffset = 0;
      }

      if (scrollPtg === 1) {
        // this.pageObj.endIndex = itemCount;
        // newStartOffset -= itemHeight;
        //offsetDiff += itemHeight; //  Math.floor(itemHeight / 2);
      }



      this.startOffset = Math.max(newStartOffset - offsetDiff, 0);
    },
    getItemKey(item, index = 0) {
      const { itemKey } = this;
      if (itemKey) {
        return isString(itemKey)
          ? item[itemKey]
          : itemKey(item);
      }
      return index;
    },

  }
}
</script>


<style lang="scss" scoped>
.VirtualList {
  position: relative;
  overflow-y: auto;
  overflow-anchor: none;
  height: 100%;
  background: #fff;
  color: #606266;
  font-size: 14px;
}
</style>
