<template>
  <span
    :style="outerStyle"
    :class="[
    'TreeNodeSwitcher', 
     !visible && 'isLeaf',
     switcherIcon, 
     expanded && 'expanded',
     ]"
    @click.stop="$emit('expand',!expanded)"
  ></span>
</template>

<script>
export default {
  name: 'TreeNodeSwitcher',
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: true,
    },
    // 是否展开
    expanded: {
      type: Boolean,
      default: false,
    },
    // 图标
    switcherIcon: {
      type: String,
      default: 'el-icon-caret-right'
    },
    // 大小
    size: {
      type: Number,
      default: 12,
    }
  },
  computed: {
    outerStyle() {
      return {
        fontSize: `${this.size}px`
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.TreeNodeSwitcher {
  padding: 6px;
  cursor: pointer;
  // color: #c0c4cc;
  transform: rotate(0);
  transition: transform 0.3s ease-in-out;
}
.expanded {
  transform: rotate(90deg);
}
.isLeaf {
  color: transparent;
  cursor: default;
}
</style>