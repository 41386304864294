<template>
  <div :style="outerStyle">
    <div :style="innerStyle">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, toRefs, CSSProperties } from 'vue';

export default {
  name: 'VirtualListFiller',
  props: {
    height: {
      type: Number,
    },
    offset: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    outerStyle() {
      return this.offset !== undefined
        ? {
          height: `${this.height}px`,
          position: 'relative',
          overflow: 'hidden',
          zIndex: 0,
        } : {};
    },
    innerStyle() {
      const commonStyle = {
        display: 'flex',
        flexDirection: 'column',
      };

      return this.offset !== undefined
        ? {
          ...commonStyle,
          transform: `translateY(${this.offset}px)`,
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
        } : {};
    }
  },

};
</script>
