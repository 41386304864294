<template>
  <div class="table-box">
    <div class="table-box-top">
      <el-button type="primary" @click="(isAdd = true), (editData = {})"
        >新增项目</el-button
      >
      <el-input
        style="width: 324px"
        placeholder="请输入项目名称"
        suffix-icon="el-icon-search"  @input="hadnleSearch"
        v-model="tablePagination.lsProjectName"
      >
      </el-input>
    </div>
    <el-table :data="tableData" stripe style="width: 100%;" height="calc(100% - 100px)">
      <el-table-column type="index" label="序号" width="80">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="lsProjectName" label="项目名称"></el-table-column>
      <el-table-column prop="parentLsName" label="上级项目" v-if="tablePagination.level > 1"></el-table-column>
      <el-table-column prop="vehicleCount" label="监控设备数"></el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column prop="createUserName" label="创建人"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" style="font-size: 12px; color: #156cf9"  @click="(isBindProject = true), (editData = scope.row)"
            >项目绑定</el-button
          >
          <el-button type="text" style="font-size: 12px; color: #156cf9" @click="(isConfigured = true), (editData = scope.row)">内容设置</el-button>
          <el-button type="text" style="font-size: 12px; color: #156cf9" @click="(isAdd = true), (editData = scope.row)">编辑</el-button>
          <el-button type="text" style="font-size: 12px; color: #156cf9" @click="handleDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tablePagination.index"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="tablePagination.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <addDialog v-model="isAdd" :tablePagination="tablePagination" :editData="editData" @changeList="getList" />
    <bindProject v-model="isBindProject" :editData="editData" @changeList="getList" />
    <configuredDialog v-model="isConfigured" :editData="editData" />
  </div>
</template>

<script>
import addDialog from "@/views/OperationBigScreen/maintenanceConfiguration/components/addDialog.vue";
import bindProject from "@/views/OperationBigScreen/maintenanceConfiguration/components/bindProject.vue";
import configuredDialog from "@/views/OperationBigScreen/maintenanceConfiguration/components/configuredDialog.vue";
import { projectLSList, projectLSDelete } from "@/api/riskData.js";
export default {
  props: {
    menuData: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tableData: [],
      tablePagination: {
        size: 10,
        index: 1,
        lsProjectName: "",
        level: '',
        type: '',
      },
      total: 0,
      editData: {},
      isAdd: false,
      isBindProject: false,
      isConfigured: false,
      timeObj: null,
    };
  },
  components: { addDialog, bindProject, configuredDialog },
  watch: {
    menuData: {
      handler(v) {
      this.tablePagination.index = 1;
      let typeLevel = this.menuData.split("-");
        this.tablePagination.level = typeLevel[1];
        this.tablePagination.type = typeLevel[0];
        this.getList();
      },
      immediate: true,
    },
  },
  methods: {
    async getList() {
      let {data, code} = await projectLSList({
        ...this.tablePagination,
      });
      if (code == 200) {
        this.total = data.total;
        // if (this.tablePagination.level == 2) {
        //   let tableData = [];
        //   data.records.map(val=> {
        //     tableData.push(...val.projectLsSonList);
        //   })
        //   this.tableData = tableData || [];
        //   return false;
        // }
        this.tableData = data.records || [];
      }else {
        this.total = 0;
        this.tableData = [];
      }
    },
    handleSizeChange(e) {
      this.tablePagination.size = e;
      this.total > this.tablePagination.size * this.tablePagination.index ? '' : this.tablePagination.index = Math.floor(this.total / this.tablePagination.size);
      this.getList();
    },
    handleCurrentChange(e) {
      this.tablePagination.index = e;
      this.getList();
    },
    hadnleSearch(e) {
      clearTimeout(this.timeObj);
      this.timeObj = setTimeout(()=> {
        this.getList();
        clearTimeout(this.timeObj);
      }, 500);
    },
    handleDel(e) {
      this.$confirm("确认删除项目?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        projectLSDelete({
          lsProjectId: e.id
        }).then((res) => {
          this.getList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.table-box {
  flex: 1;
  height: 100%;
  padding: 20px;
  background-image: url("../../../../assets/images/OperationBigScreen/right-bg.png");
  background-size: 100% 100%;
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    .el-button {
      background: #156cf9;
      border: 1px solid #156cf9;
    }
    ::v-deep .el-input {
      input {
        color: #8385b4;
        border: 1px solid #212142;
        background-color: transparent;
      }
    }
  }
  > ::v-deep .el-table {
    margin-bottom: 24px;
    border: 1px solid #2d7dff;
    background-color: transparent;
    tr {
      background-color: transparent;
    }
    .el-table__cell {
      background-color: transparent;
    }
    .el-table__header {
      .is-leaf {
        color: #b6bdd7;
        border: none !important;
        background-color: #03286d;
      }
      .cell {
        border-left: 1px solid rgba(131, 133, 180, 1);
        font-size: 14px;
        font-weight: 400;
      }
      th:first-child {
        /* 样式规则 */
        .cell {
          border-left: none !important;
        }
      }
    }
    .el-table__body-wrapper {
      td {
        color: #fff;
        border-bottom: none !important;
      }
      .el-table__row:hover {
        background-color: transparent;
        .el-table__cell {
          background-color: transparent !important;
        }
      }
      .el-table__row--striped {
        background-color: rgba(3, 40, 109, 0.4);
        .el-table__cell {
          background-color: transparent !important;
        }
      }
      .el-table__row--striped:hover {
        background-color: rgba(3, 40, 109, 0.4);
      }
    }
  }
  ::v-deep .el-table::before {
    display: none;
  }
  ::v-deep .el-pagination {
    .el-input {
      input {
        color: #8385b4;
        border: 1px solid #212142;
        background-color: transparent;
      }
    }
    .btn-prev,
    .btn-next {
      background-color: transparent;
    }
    .el-pager {
      background-color: transparent;
      li {
        background-color: transparent;
      }
      .active {
        color: #fff;
        background: #156cf9;
        border-radius: 2px;
      }
    }
  }
}
</style>