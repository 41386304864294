<template>
  <div class="dj">
    <el-menu
      default-active="1-1"
      class="el-menu-vertical-demo"
      @select="handleSelect"
    >
      <el-submenu index="1">
        <template slot="title">
          <span>商车项目</span>
        </template>
          <el-menu-item index="1-1">一级项目</el-menu-item>
          <el-menu-item index="1-2">二级项目</el-menu-item>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title">
          <span>工程机械</span>
        </template>
          <el-menu-item index="2-1">一级项目</el-menu-item>
          <el-menu-item index="2-2">二级项目</el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  methods: {
    handleSelect(e) {
      this.$emit('changeSelect', e)
    },
    handleClose() {

    },
  }
};
</script>

<style lang="scss" scoped>
.dj {
  width: 278px;
  min-width: 278px;
  height: 100%;
  padding: 20px;
  background-image: url("../../../../assets/images/OperationBigScreen/left-bg.png");
  background-size: 100% 100%;
  ::v-deep .el-menu{
    background-color: transparent;
    border: none !important;;
    .el-menu-item {
      color: #8395B4;
    }
    .el-menu-item:hover {
      color: #FFFFFF;
      background-color: transparent;
    }
    .el-submenu__title:hover {
      color: #FFFFFF;
      background-color: transparent;
    }
    .el-submenu__title {
      color: #8395B4;
    }
    .is-active {
      .el-submenu__title {
        color: #FFFFFF;
      }
      .is-active {
        position: relative;
        color: #FFFFFF;
        font-family: element-icons !important;
        background: #156CF9;
        border-radius: 4px;
      }
      .is-active::after {
        content: "\e791";
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #fff;
      }
    }
  }
}
</style>