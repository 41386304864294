<template>
  <el-dialog
    :visible.sync="dialogVisible"
    @close="clearClose"
    :close-on-click-modal="false"
    custom-class="add-risk2-dialog"
    title="项目绑定"
    width="969px"
  >
    <div slot="footer">
      <el-button @click="clearClose">关 闭</el-button>
      <el-button @click="confirm" type="primary">确 认</el-button>
    </div>
    <div class="sb-select">
      <div class="sb-select-item">
        <div class="sb-select-item-title">
          <div class="sb-select-item-title-left">
            <el-checkbox v-model="allChecked"></el-checkbox>
            <span style="margin-left: 6px">全选({{ allNum }})</span>
          </div>
          <!-- <div class="sb-select-item-title-right">
            未绑定客户({{ allNum - (rightGroupData.length || 0) }})
          </div> -->
        </div>
        <div class="sb-select-item-content">
          <GroupTree
            class="group-item_tree"
            isSearch isSelectedsReset
            :showCheckbox="multipleProp.groupCheckBox"
            ref="groupTree"
            @checkChangeGroup="careAboutYou"
            @loadElement="handleLoadElement"
            :useDefault="true"
            :isShowCount="false"
          />
          
            <!-- :selecteds="rightGroupData" -->
        </div>
      </div>
     <div class="sb-select-tool" v-loading="isThrottle">
         <!-- <div class="sb-select-tool-item" @click="careAboutYou">
          <i class="el-icon-arrow-right"></i>
        </div>
        <div
          class="sb-select-tool-item"
          style="background: rgba(66, 120, 201, 0.43)"
        >
          <i class="el-icon-arrow-left"></i>
        </div> -->
      </div>
      <div class="sb-select-item">
        <div class="sb-select-item-title">
          <div class="sb-select-item-title-left">
            <span style="margin-left: 6px"
              >已选({{ rightGroupData.length || 0 }})</span
            >
          </div>
          <div
            class="sb-select-item-title-right"
            style="font-size: 14px; color: #f64a2d; cursor: pointer" v-loading="isThrottle"
            @click="handleEmptied"
          >
            清空
          </div>
        </div>
        <div class="sb-select-item-content" style="padding: 8px 0px;" v-loading="isThrottle">
          <VirtualTree
            ref="virtualTreeRef"
            :emptyText="emptyText"
            :data="rightGroupData"
            :defaultExpandAll="defaultExpandAll"
            :defaultCheckedKeys="checkGroupKeyList"
            :showCheckbox="showCheckbox"
            :rootParentKey="-1"
            :fieldNames="{
              key: 'id',
              parentKey: 'parentId',
              label: 'name',
            }"
          >
            <template v-slot="{ node, data }">
              <div class="group-node">
                <!-- 组织名 -->
                <div class="nodeLabel" :title="data.name">
                  <img
                    src="../.../../../../../assets/images/OperationBigScreen/sb-cz.png"
                    alt=""
                    srcset=""
                  />
                  <span>{{ data.name }}</span>
                </div>
                <!-- 操作 -->
                <label>
                  <img @click="handelDelRight(data)"
                    class="del-img"
                    src="../.../../../../../assets/images/OperationBigScreen/sb-del.png"
                    alt=""
                    srcset=""
                  />
                </label>
              </div>
            </template>
          </VirtualTree>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { inputNum } from "@/views/routerProjectManagement/components/ProjectList/components/utils.js";
import GroupTree from "@/components/GroupTree/ProjectTree";
import VirtualTree from "@/components/VirtualTree/NewTree";
import { insSaveProject, insUpdateProject } from "@/api/basicData";
import { dictionaryBatch } from "@/api/policy";
import { addProject } from "@/api/project";
import { arrayTreeSort } from "@/utils/treeHelper.js";
import { projectLSProjectBind, projectBindList } from "@/api/riskData.js";
export default {
  name: "addctbxProjectDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },
  components: { GroupTree, VirtualTree },
  data() {
    return {
      dialogVisible: false,
      topLevelId: 0,
      riskType: [],
      productTypeList: [],
      form: {
        projectName: "",
        productType: "",
        productTypeValue: "",
        enable: true,
      },
      rules: {
        productType: [
          { required: true, message: "请选择产品线", trigger: "change" },
        ],
        projectName: [
          { required: true, message: "请输入项目名称", trigger: "change" },
        ],
      },
      multipleProp: {
        // 车组多选和车辆多选不能同时为true 同时设置了优先显示车组
        noGroup: true, // 是否可选车组
        groupCheckBox: true, //车组多选
        vehicleCheckBox: false, //车辆多选
      },
      selecteds: [],
      allChecked: false, //
      defaultProps: {
        label: "groupName",
        children: "zones",
      },
      sbData: [],
      leftGroupData: [],
      rightGroupData: [],

      emptyText: "--",
      defaultExpandAll: true, //
      checkGroupKeyList: [],
      showCheckbox: false, //
      allNum: 0,
      isThrottle: false, //
      bindIds: [], //
    };
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }
      },
    },
    allChecked: {
      handler(v) {
        if (v) {
          this.$refs.groupTree.selectAll();
        } else {
          this.$refs.groupTree.cancelAll();
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          if (this.editData.id) {
            let data = JSON.parse(JSON.stringify(this.editData));
            this.$nextTick(() => {
              Object.assign(this.form, data);
            });
            this.init();
          }
        } else {
          this.dialogVisible = false;
        }
      },
    },
  },
  mounted() {},
  methods: {
    reset() {
      this.leftGroupData = [];
      this.rightGroupData = [];
      this.clearClose();
    },
    init() {
      projectBindList({ lsProjectId: this.editData.id }).then((res) => {
        this.bindIds = res.data;
        if (this.editData.id && this.allNum) {
          this.$refs.groupTree.setCheckedKey(this.bindIds || []);
          this.$nextTick(()=> {
            this.leftGroupData = this.$refs.groupTree.getCheckedNodes().map(obj => obj);
            this.careAboutYou();
          });
        }else {
          this.$refs.groupTree.setCheckedKey([]);
        }
      });
    },
    checkChangeGroup(e) {
      this.leftGroupData = e;
    },
    clearClose() {
      this.dialogVisible = false;
    },
    async confirm() {
      let projectIds = this.rightGroupData.map(val=> val.id);
      let data = await projectLSProjectBind({...this.editData, projectIds});
      this.dialogVisible = false;
      this.$emit('changeList');
    },
    onSelected(e) {
      this.form.dangerProvince = e.province.value;
      this.form.dangerCity = e.city.value;
      this.form.dangerCounty = e.area.value;
      this.form.riskLocations = e.province.value + e.city.value + e.area.value;
    },
    input_num(e, tiem, key, max, decimal = 2) {
      inputNum(e, tiem, key, max, decimal);
    },
    handleChangProductType(e) {
      let data = this.productTypeList.find((p) => p.dictValue == e);
      this.form.productTypeValue = data.dictKey;
    },
    careAboutYou() {
      this.leftGroupData = this.$refs.groupTree.getCheckedNodes().map(obj => obj);
      this.rightGroupData = JSON.parse(JSON.stringify(this.leftGroupData));
    },
    handleLoadElement(e) {
      this.allNum = e;
      if (this.editData.id && this.bindIds) {
         this.$refs.groupTree.setCheckedKey(this.bindIds);
          this.$nextTick(()=> {
            this.leftGroupData = this.$refs.groupTree.getCheckedNodes().map(obj => obj);
            this.careAboutYou();
          });
      }
    },
    handelDelRight(e) {
      this.isThrottle = true;
      let index = this.rightGroupData.findIndex(val=> val.id == e.id);
      this.$refs.groupTree.setChecked(this.rightGroupData[index].id, false, false);
      this.$nextTick(()=> {
        this.leftGroupData = this.$refs.groupTree.getCheckedNodes().map(obj => obj);
        this.careAboutYou();
      });
      setTimeout(()=> {this.isThrottle = false;}, 500);
    },
    handleEmptied() {
      this.isThrottle = true;
      this.$refs.groupTree.cancelAll([], false, false);
      this.$nextTick(()=> {
        this.rightGroupData = [];
      });
      setTimeout(()=> {this.isThrottle = false;}, 500);
    }
  },
};
</script>

<style lang="scss">
.add-risk2-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 16px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    padding: 0 24px;
    background: #fff;
    border-bottom: 1px solid #F5F5F5;
    margin-bottom: 14px;
    .el-dialog__title {
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 0;
  }
  .sb-select {
    display: flex;
    align-content: center;
    height: 660px;
    &-item {
      flex: 1;
      height: 100%;
      overflow: auto;
      border: 1px solid #dee3e6;
      &-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        padding: 0 14px;
        background: #f2f7fd;
        border-bottom: 1px solid #dee3e6;
        &-left {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #333333;
        }
      }
      &-content {
        padding: 8px 16px;
        .group-node {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .nodeLabel {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #333333;
            img {
              width: 16px;
              height: 16px;
              margin-right: 2px;
            }
          }
          .del-img {
            cursor: pointer;
            width: 20px;
            height: 20px;
            margin-right: 16px;
            display: none;
          }
        }
        .group-node:hover {
          .del-img {
            display: block;
          }
        }
      }
    }
    &-tool {
      width: 65px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        font-size: 24px;
        color: #fff;
        cursor: pointer;
        margin-bottom: 10px;
        background: #4278c9;
        border-radius: 4px 4px 4px 4px;
      }
    }
  }
}
</style>
