<template>
  <div class="nmd-table">
    <div v-for="item,index in list" :key="index">
      <div class="nmd-table-title">
        {{ item.label || '--' }}
      </div>
      <el-table
        :data="item.tableData"
        border
        style="width: 100%">
        <el-table-column
          :prop="column.prop" v-for="column,ind in item.tableDataList" :key="ind" :width="column.width"
          :label="column.label">
          <template slot-scope="scope">
            <span v-if="column.columnType == 'text'">{{ dataTypeNameList[scope.row[column.prop]] }}</span>
            <el-input v-else-if="column.columnType == 'input'"
                v-model="item.tableData[scope.$index][column.prop]" type="number"
                @input="
                  input_num($event, item.tableData[scope.$index], column.prop, 99999999999999, 0)
                "
              ></el-input>
            <textSwitch v-model="item.tableData[scope.$index][column.prop]" v-else-if="column.columnType == 'switch'" active-text="否" inactive-text="是" @change="handleChangeDispaly($event, scope.$index, item.tableData[scope.$index])" />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import textSwitch from "@/components/textSwitch/index.vue";
import { inputNum } from "@/views/routerProjectManagement/components/ProjectList/components/utils.js";
export default {
  components: { textSwitch },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: "list",
    event: "change",
  },
  data() {
    return {
      dataTypeNameList: [
        '',
        '数据概览',
        '报警统计',
        '安全指数',
        '实时报警',
        '超速报警',
        'AEB制动报警',
        '前向碰撞预警',
        '车距检测预警',
        '车道偏离预警',
        '超速报警',
        'AEB制动报警',
        '前向碰撞预警',
        '车距检测预警',
        '车道偏离预警',
      ]
    }
  },
  methods: {
    handleChangeDispaly(e, ind, item) {
      console.log(e, ind, item);
    },
    input_num(e, tiem, key, max, decimal = 2) {
      inputNum(e, tiem, key, max, decimal);
    },
  },
};
</script>

<style lang="scss" scoped>
.nmd-table {
  &-title {
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    margin-bottom: 12px;
    margin-top: 16px;
  }
  ::v-deep .el-table {
      margin-bottom: 22px;
      .el-table--border {
          border-bottom: 1px solid #EBEEF5;
      }
      .has-gutter {
        tr {
          .el-table__cell {
            background-color: #f2f7fd;
            font-size: 14px;
            color: #666666;
            font-weight: 400;
          }
          .is-leaf {
            border-bottom: 1px solid #dee3e6;
          }
        }
      }
      tr {
        .el-table__cell {
          font-size: 14px;
          color: #333;
          font-weight: 400;
        }
        .is-leaf {
          border-bottom: 1px solid #dee3e6;
        }
      }
    }
}
</style>